import Decimal from '@st/decimal'
import { useCurrencies } from '@st/payments/stores/currencies'
import useCryptoFormatter from '@st/i18n/composables/useCryptoFormatter'
import useNumberFormatter from '@st/i18n/composables/useNumberFormatter'
import useCurrencyFormatter from '@st/i18n/composables/useCurrencyFormatter'
import type { DepositBonus } from '../types'
import { useBonusesStore } from '../stores/useBonusesStore'
import { useDepositBonusesStore } from '../stores/useDepositBonusesStore'

export function useDepositBonus(bonus: Ref<DepositBonus | null>) {
  const currenciesStore = useCurrencies()
  const { currencies, appCurrency } = storeToRefs(currenciesStore)
  const { format: formatCrypto } = useCryptoFormatter()
  const { format: formatCurrency } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })
  const { format: formatMultiplier } = useNumberFormatter()

  const bonusMultiplier = computed(
    () =>
      `+${formatMultiplier(
        new Decimal(bonus.value?.bonusSettings.depositMultiplier ?? 0)
          .mul(100)
          .toString(),
      )}%`,
  )
  const rollingMultiplier = computed(() =>
    formatMultiplier(bonus.value?.bonusSettings.rollingMultiplier ?? 0),
  )

  const maxDepositAmount = computed(() =>
    formatCrypto(bonus.value?.bonusSettings.maxBonusAmountInBonusCurrency ?? 0),
  )

  const minDepositAmount = computed(() =>
    formatCurrency(bonus.value?.minDepositAmountInAppCurrency ?? 0),
  )

  const currencyIcon = computed(() =>
    bonus.value?.bonusSettings.currencyId
      ? currencies.value[bonus.value.bonusSettings.currencyId]?.icon
      : undefined,
  )

  const currencyCode = computed(() =>
    bonus.value?.bonusSettings.currencyId
      ? currencies.value[bonus.value.bonusSettings.currencyId]?.code
      : undefined,
  )

  const isNewBonus = computed(() => !bonus.value?.bonus)
  const isRolling = computed(
    () =>
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency &&
      new Decimal(
        bonus.value.bonus.currentRollingAmountInBonusCurrency,
      ).lessThan(bonus.value.bonus.fullRollingAmountInBonusCurrency),
  )
  const isCanClaimed = computed(
    () =>
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency &&
      new Decimal(
        bonus.value.bonus.currentRollingAmountInBonusCurrency,
      ).greaterThanOrEqualTo(
        bonus.value.bonus.fullRollingAmountInBonusCurrency,
      ),
  )

  const bonusAmountForClaim = computed(() =>
    formatCrypto(bonus.value?.bonus?.amountInBonusCurrency ?? 0),
  )

  const currentRollingAmount = computed(() =>
    formatCrypto(bonus.value?.bonus?.currentRollingAmountInBonusCurrency ?? 0),
  )
  const fullRollingAmount = computed(() =>
    formatCrypto(bonus.value?.bonus?.fullRollingAmountInBonusCurrency ?? 0),
  )

  const rollingProgress = computed(() => {
    if (
      !bonus.value?.bonus?.needRolling ||
      !bonus.value?.bonus?.currentRollingAmountInBonusCurrency
    )
      return ''

    return new Decimal(bonus.value.bonus.currentRollingAmountInBonusCurrency)
      .dividedBy(bonus.value.bonus.fullRollingAmountInBonusCurrency)
      .mul(100)
      .toFixed(2)
      .toString()
  })

  const leftSumAmount = computed(() => {
    if (!bonus.value?.bonus) return '0'
    return new Decimal(bonus.value.bonus.fullRollingAmountInBonusCurrency)
      .minus(bonus.value.bonus.currentRollingAmountInBonusCurrency)
      .toString()
  })

  const { format: formatCryptoWithCurrencyCode } = useCryptoFormatter({
    style: 'currency',
    currency: currencyCode.value,
  })

  const fullRollingAmountWithCurrency = computed(() =>
    formatCryptoWithCurrencyCode(
      bonus.value?.bonus?.fullRollingAmountInBonusCurrency ?? 0,
    ),
  )

  const currentRollingAmountWithCurrency = computed(() =>
    formatCryptoWithCurrencyCode(
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency ?? 0,
    ),
  )

  const maxDepositAmountWithCurrency = computed(() =>
    formatCryptoWithCurrencyCode(
      bonus.value?.bonusSettings.maxBonusAmountInBonusCurrency ?? 0,
    ),
  )

  const { updateBonusCounters } = useBonusesStore()
  const { fetchDepositBonuses } = useDepositBonusesStore()
  const { execute: claim, status: claimStatus } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value?.bonus?.id ?? 0,
    })),
    immediate: false,
    watch: false,
  })

  async function handleBonusClaim() {
    try {
      await claim()
      await fetchDepositBonuses()
      await updateBonusCounters()
    } catch (err) {
      console.error(err)
    }
  }

  return {
    bonusMultiplier,
    maxDepositAmount,
    currencyIcon,
    minDepositAmount,
    isNewBonus,
    isRolling,
    isCanClaimed,
    bonusAmountForClaim,
    currentRollingAmount,
    fullRollingAmount,
    rollingMultiplier,
    rollingProgress,
    claimStatus,
    leftSumAmount,
    handleBonusClaim,
    currencyCode,
    formatCryptoWithCurrencyCode,
    fullRollingAmountWithCurrency,
    currentRollingAmountWithCurrency,
    maxDepositAmountWithCurrency,
  }
}
